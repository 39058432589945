






































































































import {
  defineComponent,
  computed,
  ref,
  watch,
  useFetch,
  useRoute,
  useContext
} from '@nuxtjs/composition-api';
import { useMegaMenuStore } from '@wemade-vsf/megamenu';
import type  { MegaMenuItemTree } from '@wemade-vsf/megamenu';
import { SfOverlay } from '@storefront-ui/vue';
import ClickOutside from 'vue-click-outside';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

export default defineComponent({
  name: 'MegaMenu',
  props: {
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SfOverlay
  },
  directives: {
    ClickOutside
  },
  setup (props) {
    const { app } = useContext();
    const menuStore = useMegaMenuStore();
    const isClickOpener = app.$wm.theme.menuOpener === 'click';

    const activeCategory = ref<number | null | undefined>(null);
    const activeSubCategory = ref<number | null | undefined>(null);

    const isOpen = (items_id: number | undefined) => items_id === activeCategory.value;

    let openTimeout: ReturnType<typeof setTimeout>|null = null
    const enter = (item: MegaMenuItemTree) => {
      if (openTimeout) {
        return
      }
      openTimeout = setTimeout(() => {
        activeCategory.value = item.items_id;
      }, isClickOpener ? 100 : 300)
    }

    const leave = () => {
      if (openTimeout) {
        clearTimeout(openTimeout)
      }
      openTimeout = null
      activeCategory.value = null
    }

    const closeMenu = () => {
      activeCategory.value = null
      clearBodyLocks();
    }

    const toggleMenu = (item: MegaMenuItemTree) => {
      if (isOpen(item.items_id)) {
        closeMenu();
      } else {
        activeCategory.value = item.items_id;
        lock();
      }
    }    

    const items = computed(() => menuStore.items.map((item) => {
      const isClickable = isClickOpener && (item.children?.length || 0) > 0
      return {
        ...item,
        isVertical: props.isVertical || item.cols_nb === 1,
        isClickable,
        componentProps: isClickable
          ? { is: 'button' }
          : getTitleProps(item)
      }
    }))
    
    

    const categoryClasses = (category: MegaMenuItemTree) => {
      const open = isOpen(category.items_id);
      return [
        'level-0',
        ...category.cols_nb === 1 ? ['menu-item--vertical'] : [],
        open ? 'open' : null,
        category.custom_class ? category.custom_class : null
      ]
    }

    const subCategoryClasses = (category: MegaMenuItemTree) => {
      const open = activeSubCategory.value === category.items_id;
      return [
        'menu-subcategory',
        'level-1',
        open ? 'open' : null,
        `col-md-${category.cols_nb || 6}`,
        category.custom_class ? category.custom_class : null
      ]
    }

    const getTitleProps = (item: MegaMenuItemTree) => {
      let is = 'span';
      let props = {};
      if (['category', 'product', 'page', 'internal'].includes(item.link_type || '') && item.link) {
        is = 'nuxt-link'
        props['to'] = app.localePath(`${item.link?.startsWith('/') ? '' : '/'}${item.link}`)
      } else if (item.link) {
        is = 'a'
        props['href'] = item.link
      }
      if (!['category', 'page'].includes(item.link_type || '') && item.target && item.target !== '_self') {
        props['target'] = item.target
        props['rel'] = 'noopener'
      }

      return { is, ...props }
    }
    
    useFetch(async() => {
      if (items.value.length === 0) {
        await menuStore.load()
      }
    });

    const route = useRoute();
    watch(() => route.value, () => {
      activeCategory.value = null;
    });

    return {
      items,
      activeCategory,
      activeSubCategory,
      isOpen,
      categoryClasses,
      subCategoryClasses,
      getTitleProps,
      toggleMenu,
      closeMenu,
      leave,
      enter,
    }
  }
})
